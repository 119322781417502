
import MainTable from "@/modules/purchaseInvoices/components/MainTable.vue";
import SearchForm from "@/modules/purchaseInvoices/components/SearchForm.vue";

export default {
    components: {SearchForm, MainTable},
    setup() {
        const tableName = 'purchaseInvoices'

        return {
            tableName
        }
    }
}
