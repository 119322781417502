import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {PurchaseInvoiceCatalogs, PurchaseInvoiceForm} from "@/modules/purchaseInvoices/interfaces";
import store from "@/store";
import moment from "moment";

const {companyId} = useAuth()
const defaultValues = (): PurchaseInvoiceForm => {
    const id = uuid();

    return {
        id,
        code: '',
        reference: '',
        accountingCenterId: '',
        accountingAccountPayableId: '',
        locationId: '',
        providerId: '',
        paymentTerm: '',
        status: 'to_be_approved',
        creationDate: moment().format('DD/MM/YYYY'),
        companyId: companyId.value,
        subtotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
        items: [
            {
                id: uuid(),
                categoryId: "",
                itemId: "",
                quantity: 1,
                unitId: "",
                unitConversionFactor: 1.00,
                unitPrice: 0,
                taxId: "",
                taxPercentage: 0,
                discountPercentage: 0.00,
                accountingAccountId: "",
                locationId: "",
                accountingCenterId: "",
                purchaseInvoiceId: id
            }
        ]
    }
}

const purchaseInvoiceForm: Ref<PurchaseInvoiceForm> = ref(defaultValues())

const usePurchaseInvoice = () => {
    const clearForm = () => purchaseInvoiceForm.value = defaultValues()

    const createPurchaseInvoice = async (purchaseInvoiceForm: PurchaseInvoiceForm) => {
        return await store.dispatch('purchaseInvoices/createPurchaseInvoice', purchaseInvoiceForm)
    }

    const updatePurchaseInvoice = async (purchaseInvoiceForm: PurchaseInvoiceForm) => {
        return await store.dispatch('purchaseInvoices/updatePurchaseInvoice', purchaseInvoiceForm)
    }

    const getPurchaseInvoice = async (id: string): Promise<PurchaseInvoiceForm> => {
        const {data} = await store.dispatch('purchaseInvoices/getPurchaseInvoice', id)

        return data;
    }

    const getCatalogs = async (): Promise<PurchaseInvoiceCatalogs> => {
        const {data} = await store.dispatch('purchaseInvoices/getCatalogs', companyId.value)

        return data;
    }

    return {
        purchaseInvoiceForm,
        clearForm,
        createPurchaseInvoice,
        updatePurchaseInvoice,
        getPurchaseInvoice,
        getCatalogs
    }
}

export default usePurchaseInvoice;
