<template>
  <div class="xcontainer">
    <div>
      <div
        class="align-items-center d-flex div-title-card justify-content-between row"
      >
        <div class="align-items-baseline d-sm-flex flex-md-row flex-sm-column">
          <h5 class="xtitle-buscar">Lista de facturas de compras</h5>
          <p class="ml-md-3 ml-sm-0 pt-md-0 pt-sm-1 xsubtitle-buscar">
            (Tabla principal)
          </p>
        </div>
        <a href="#" @click.prevent="togglePanel">
          <i
            class="fa"
            :class="{
              'fa-chevron-down': !showPanel,
              'fa-chevron-up': showPanel,
            }"
          ></i
        ></a>
      </div>

      <div :class="{ collapse: !showPanel }">
        <div class="table-responsive">
          <table :id="'list' + tableName">
            <tr>
              <td></td>
            </tr>
          </table>
          <div :id="'pager' + tableName"></div>
        </div>
      </div>
    </div>
    <amortizer-modal ref="amortizerModal"></amortizer-modal>
  </div>
</template>

<script>
import { onMounted, ref } from "vue"
import jqgrid from "@/modules/purchaseInvoices/config/jqgrid"
import useAuth from "@/modules/auth/composables/useAuth"
import usePanel from "@/composables/usePanel"
import useMainTable from "@/composables/useMainTable"
import router from "@/router"
import AmortizerModal from "@/modules/purchaseInvoices/components/amortizerModal"

export default {
  components: { AmortizerModal },
  props: ["tableName"],
  setup(props) {
    const { companyId } = useAuth()
    const { showPanel, togglePanel } = usePanel()
    const amortizerModal = ref(null)

    onMounted(() => {
      const body = window.$("body")
      const list = body.find("#list" + props.tableName)
      const pager = body.find("#pager" + props.tableName)

      const { onViewOptions, onShow, onChangeStatus, onStateButton } =
        useMainTable(props.tableName)

      onViewOptions("purchase_invoices/options_list")
      onShow("purchase_invoices.edit")
      onChangeStatus("purchase_invoices/status_list")
      onStateButton("purchase_invoices/status_list")

      body.off("click", ".toPay").on("click", ".toPay", function (e) {
        e.preventDefault()

        const modal = window.$("#optionsModal")
        modal.modal("hide")

        const id = window.$(this).attr("data-id")
        router.push({
          name: "payments.create",
          query: { starterType: "purchase_invoice", starterId: id },
        })
      })

      body
        .off("click", ".toAmortize")
        .on("click", ".toAmortize", async function (e) {
          e.preventDefault()

          const modal = window.$("#optionsModal")
          modal.modal("hide")

          const id = window.$(this).attr("data-id")
          await amortizerModal.value.show(id)
          list.trigger("reloadGrid")
        })

      list.jqGrid({
        ...jqgrid,
        pager,
        postData: {
          filters: [{ field: "companyId", value: companyId.value }],
        },
      })
    })

    return {
      showPanel,
      togglePanel,
      amortizerModal,
    }
  },
}
</script>
