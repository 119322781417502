<template>
  <teleport to="body">
    <div
      class="modal fade"
      :id="name"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Aplicar crédito a favor</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="hideModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4" v-if="!loading">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Crédito a favor</label>
                <input
                  type="text"
                  class="form-control"
                  disabled=""
                  :value="provider.balance"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nro. documento</th>
                      <th>Monto</th>
                      <th>Saldo</th>
                      <th>Pago</th>
                    </tr>
                  </thead>
                  <tbody v-if="providerAdvances.length > 0">
                    <tr v-for="a in providerAdvances" :key="a.id">
                      <td v-text="a.fullName"></td>
                      <td v-html="a.amount"></td>
                      <td v-html="a.balanceTag"></td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="a.total"
                          @change="fixTotal(a)"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div class="input-group">
                          <span class="input-group-addon"
                            ><i class="fa fa-dollar"></i
                          ></span>
                          <input
                            placeholder=""
                            type="text"
                            disabled="disabled"
                            class="form-control"
                            :value="sumTotal"
                          />
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-xs-0 col-sm-6 col-md-8 col-lg-8">&nbsp;</div>
              <div class="form-group col-md-6">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-w-m btn-gray btn-block"
                >
                  <i class="fa fa-ban"></i> Cancelar
                </button>
              </div>
              <div class="form-group col-md-6">
                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  @click.prevent="confirm"
                  :disabled="sumTotal === 0 || loading"
                >
                  <i class="fa fa-save"></i> Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, ref } from "vue"
import { v4 as uuid } from "uuid"
import erpApi from "@/api/erpApi"
import useProvider from "@/modules/providers/composables/useProvider"
import usePurchaseInvoice from "@/modules/purchaseInvoices/composables/usePurchaseInvoice"
import useProviderAdvance from "@/modules/providerAdvances/composables/useProviderAdvance"
import toastr from "toastr"

export default {
  setup() {
    const name = ref("purchaseInvoiceAmortizerModal")
    let purchaseInvoice
    let _resolve

    const provider = ref(null)
    const providerAdvances = ref([])
    const loading = ref(true)
    const sumTotal = computed(() => {
      let total = 0
      providerAdvances.value.forEach((a) => {
        total += parseFloat(a.total)
      })
      return total
    })

    const { getPurchaseInvoice } = usePurchaseInvoice()
    const { getProvider } = useProvider()
    const { getProvidersAdvances } = useProviderAdvance()

    const showModal = () => window.$("#" + name.value).modal("show")

    const show = (purchaseInvoiceId) => {
      return new Promise((resolve) => {
        const initialize = async () => {
          loading.value = true

          showModal()

          purchaseInvoice = await getPurchaseInvoice(purchaseInvoiceId)
          provider.value = await getProvider(purchaseInvoice.providerId)
          const providersAdvancesReponse = await getProvidersAdvances([
            { field: "providerId", value: purchaseInvoice.providerId },
          ])
          providerAdvances.value = providersAdvancesReponse.map((row) => {
            return {
              id: uuid(),
              appliedId: row.id,
              appliedType: "ProviderAdvance",
              amortizedId: purchaseInvoiceId,
              amortizedType: "PurchaseInvoice",
              fullName: row.cell.fullName,
              amount: row.cell.amount,
              balance: row.cell.balance,
              balanceTag: row.cell.balanceTag,
              total: 0,
            }
          })

          loading.value = false
        }

        _resolve = resolve
        initialize()
      })
    }

    const hideModal = () => window.$("#" + name.value).modal("hide")

    return {
      name,
      show,
      showModal,
      hideModal,
      loading,
      provider,
      providerAdvances,
      sumTotal,
      fixTotal: (row) => {
        if (row.total > row.balance) {
          row.total = row.balance
        } else if (row.total < 0) {
          row.total = 0
        }
      },
      confirm: () => {
        loading.value = true
        erpApi
          .post("/purchase_invoice_amortizes", {
            providerAdvances: providerAdvances.value,
            purchaseInvoiceId: purchaseInvoice.id,
          })
          .then(() => {
            hideModal()
            _resolve()
          })
          .catch((error) => {
            loading.value = false
            console.log(error)
            toastr.error(error.response.data.message)
          })
      },
    }
  },
}
</script>

<style scoped>
th {
  width: 14%;
  color: white;
  background-color: rgb(0, 118, 190);
  border: 1px solid white;
}
</style>
