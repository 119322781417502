import useAuth from "@/modules/auth/composables/useAuth";
import { useRoute } from "vue-router";
import { computed, Ref, ref } from "vue";
import { v4 as uuid } from "uuid";
import { ProviderAdvanceForm } from "@/modules/providerAdvances/interfaces";
import store from "@/store";
import moment from "moment";
import erpApi from "@/api/erpApi";

const { companyId } = useAuth();
const defaultValues = (): ProviderAdvanceForm => {
    return {
        id: uuid(),
        code: "",
        providerId: "",
        date: moment().format("DD/MM/YYYY"),
        amount: 0.0,
        accountingCenterId: "",
        status: "to_be_approved",
        companyId: companyId.value,
        observations: "",
    };
};

const providerAdvanceForm: Ref<ProviderAdvanceForm> = ref(defaultValues());

const useProviderAdvance = () => {
    const catalogs = {
        status: [
            { id: "to_be_approved", title: "Por aprobar" },
            { id: "approved", title: "Aprobado" },
            { id: "canceled", title: "Anulado" },
            { id: "partial_paid", title: "Pagado parcial" },
            { id: "full_paid", title: "Pagado completo" },
        ],
    };
    const clearForm = () => (providerAdvanceForm.value = defaultValues());

    const createProviderAdvance = async (providerAdvanceForm: ProviderAdvanceForm) => {
        return await store.dispatch("providerAdvances/createProviderAdvance", providerAdvanceForm);
    };

    const updateProviderAdvance = async (providerAdvanceForm: ProviderAdvanceForm) => {
        return await store.dispatch("providerAdvances/updateProviderAdvance", providerAdvanceForm);
    };

    const getProviderAdvance = async (id: string): Promise<ProviderAdvanceForm> => {
        const { ok, data } = await store.dispatch("providerAdvances/getProviderAdvance", id);

        return data;
    };

    const getProvidersAdvances = async (filters: any[]): Promise<any> => {
        try {
            const { data } = await erpApi.get("/provider_advances?" + (window as any).$.param({ filters }));
            const { rows } = data;

            return rows;
        } catch (error) {
            console.log(error);
            return { ok: false, message: error.response.data.message };
        }
    };

    return {
        providerAdvanceForm,
        catalogs,
        clearForm,
        createProviderAdvance,
        updateProviderAdvance,
        getProviderAdvance,
        getProvidersAdvances,
        disabledStatus: computed(() => useRoute().path.includes("create")),
    };
};

export default useProviderAdvance;
