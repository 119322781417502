import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {ProviderForm} from "@/modules/providers/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): ProviderForm => {
    return {
        id: uuid(),
        name: "",
        status: "active",
        companyId: companyId.value
    }
}

const providerForm: Ref<ProviderForm> = ref(defaultValues())

const useProvider = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ],
    };
    const clearForm = () => providerForm.value = defaultValues()

    const createProvider = async(providerForm: ProviderForm) => {
        return await store.dispatch('providers/createProvider', providerForm)
    }

    const updateProvider = async (providerForm: ProviderForm) => {
        return await store.dispatch('providers/updateProvider', providerForm)
    }

    const getProvider = async (id: string): Promise<ProviderForm> => {
        const {ok, data} = await store.dispatch('providers/getProvider', id)

        return data;
    }


    return {
        providerForm,
        catalogs,
        clearForm,
        createProvider,
        updateProvider,
        getProvider
    }
}

export default useProvider;
